const isPOSInCurrentMarket = (pos, currentMarketCode = 'en-ww', marketCodesList) => {
  const countryCode = currentMarketCode.split('-')[1]
  const countryCodesList = marketCodesList.map((code) => code.split('-')[1])
  const posCountryCode = pos?.fieldPosAddress?.countryCode.toLowerCase()

  if (countryCode === 'ww') {
    return !countryCodesList.includes(posCountryCode)
  }

  return countryCode === posCountryCode
}

export default isPOSInCurrentMarket
